.download-btns button{
    display: block;
    border-radius: 0;
    width: 100%;
    margin-bottom: 0.5rem;
    padding: 0.5rem 1rem;
}

.download-btns{
    width: 80%;
    margin: 0 auto;
}

.rgu-btn-orange{
    color: #FFFFFF;
}

button.dashboard-btn, a.dashboard-btn{
    padding: 0.4rem;
  }

@media(max-width: 768px){
    .download-btns{
        width: 100%;
        margin: 0 auto;
    }

    button.dashboard-btn, a.dashboard-btn{
        padding: 0.3rem;
        font-size: 0.85rem;
      }

}