.banner-section .site-banner {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    width: 100%;
}

.banner-grid {
    height: 100%;
}

.line-height {
    line-height: 5rem;
}

.banner-grid .col-span-2,
.banner-grid .col-span-1 {
    position: relative;
    height: 100%;
    width: 100%;
    left: 0;
    bottom: 10%;
}

.banner-grid .banner-text {
    position: absolute;
    bottom: 8%;
    left: 0;
    width: 100%;
}

.banner-grid .banner-form {
    position: absolute;
    bottom: 0;
    width: 100%;
}

.banner-grid .banner-form .form-wrap {
    margin: 0 auto;
}

.banner-grid .bg-yellow {
    border-top-right-radius: 200px;
    border-bottom-right-radius: 200px;
}

.star-div {
    display: flex;
}

.star-div span {
    margin-right: 0.5rem;
    margin-top: 0.2rem;
}

#resend-btn:disabled {
    cursor: not-allowed !important;
}

.banner-notify-item {
    padding: 0.5rem;
}

@media (max-width: 768px) {
    .banner-grid .banner-form {
        position: absolute;
        top: 30%;
        width: 100%;
    }

    .banner-notify-item {
        padding: 0.5rem;
    }
}

@media (max-width: 576px) {
}
