.btn20 {
	margin-left: 40rem;
}
.log45 {
	border-top: 1px solid #cbd5e0;
}

.admit-logo{
	margin: 0 auto;
	width: 6rem;
}

.bottom-m-1{
	margin-bottom: 1rem;
}